* {
  margin: 0;
  padding: 0;
  text-decoration: none;
}

:root {
  --blue: #1e90ff;
  --white: #ffffff;
  --primary: #fc5c65;
  --secondary: #4ecdc4;
  --black: #000;
  --white: #fff;
  --medium: #6e6969;
  --light: #f8f4f4;
  --danger: #ff5252;
  --dark: #0c0c0c;
  --warn: #ffe66d;
  --blueAms: #008c8d;
  --VertAms: #a7c407;
  --JauneAms: #fbbd00;
  --VioletAms: #830046;
  --BlueLight: #02a9db;
  --GreenHard: #396f70;
  --BlueHard: #037091;
}

#root {
  height: 100vh;
  width: 100%;
}

i {
  cursor: pointer;
}

.container {
  display: flex;
}
main {
  width: 100%;
  padding: 20px;
}
.sidebar {
  background: var(--VioletAms);
  color: var(--white);
  height: 100vh;
  width: 200px;
  transition: all 0.5s;
}
.top_section {
  display: flex;
  align-items: center;
  padding: 5px 15px;
}
.logo {
  font-size: 30px;
}
.bars {
  display: flex;
  font-size: 25px;
  margin-left: 50px;
}
.link {
  display: flex;
  color: #fff;
  padding: 10px 15px;
  gap: 15px;
  transition: all 0.5s;
}
.link:hover {
  background: var(--VioletAms);
  color: #000;
  transition: all 0.5s;
}
.active {
  background: var(--VertAms);
  color: #000;
}
.icon,
.link_text {
  font-size: 20px;
}

@media screen and (max-width: 750px) {
  .sidebar {
    height: auto;
  }
}
div.container:nth-child(2) {
  display: flex;
  justify-content: space-around;
}

.login .loginform {
  width: 80%;
  margin: auto;
  background-color: rgba(255, 255, 255, 0.2);
  padding: 20px;
  border-radius: 15px;
}

.login .loginform .head {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-bottom: 20px;
}

.login .loginform .head h2 {
  color: #fff;
  font-weight: 800;
}

.login .loginform .notfound {
  animation: bounce 0.8s infinite alternate;
}

@keyframes bounce {
  0% {
    transform: translateY(0);
  }

  100% {
    transform: translateY(-50px);
  }
}

.login .loginform img {
  width: 250px;
  height: 250px;
  border-radius: 50%;
  transition: all 1s;
  cursor: pointer;
}

.login .loginform img:hover {
  transform: scale(1.1, 1.1);
}

.login .loginform .loginfields {
  width: 50%;
  margin: auto;
}

.userpage {
  background-color: #f8f9fa;
  margin-top: 20px;
  border-radius: 10px;
}

table tr {
  background-color: var(--light);
  color: #000;
  border-color: var(--blueAms);
  border-width: 2px;
  border-style: solid;
}

table thead {
  color: #000;
  border-width: 2px;
  border-style: double;
  border-color: #000;
}

@media screen and (max-width: 950px) {
  table thead {
    display: none;
  }
  div .eventCard {
    margin-left: 10px !important;
    margin-right: 10px !important;
  }

  .audit .tableau {
    overflow-y: scroll;
    margin-bottom: 15px;
    margin-top: 15px;
  }

  form.modalform {
    padding: 30px;
    width: 70%;
    margin: auto;
  }

  .login .loginform .loginfields {
    width: 100%;
  }

  .login .loginform .head {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-bottom: 40px;
  }

  .login .loginform .loginfields {
    width: 100%;
  }

  .login .loginform img {
    width: 150px;
    height: 150px;
    border-radius: 50%;
    transition: all 1s;
    cursor: pointer;
  }

  .sidebar {
    display: none;
  }
  table tr {
    padding: 5px;
    display: block;
    margin: 0.5rem 0 1rem;
    background-color: #fff;
    border-radius: 2px;
    overflow: auto;
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16),
      0 2px 10px 0 rgba(0, 0, 0, 0.12);
  }

  table td {
    display: block;
    text-align: right;
    border-bottom: 1px dotted #ccc;
  }

  table td:last-child {
    border-bottom: 0;
  }

  table td:before {
    content: attr(data-label);
    float: left;
    font-weight: bold;
  }

  /*.container .cap .mcaption {
    flex-direction: column;
  }

  .container .cap .mcaption .exportbtn {
    justify-content: center;
    display: flex;
  }*/

  .navbar ul li {
    display: flex;
    justify-content: center;
  }
}

.bouncing-loader {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.bouncing-loader > div {
  width: 35px;
  height: 35px;
  margin: 3px 6px;
  border-radius: 50%;
  background-color: red;
  opacity: 1;
  animation: bouncing-loader 0.6s infinite alternate;
}

@keyframes bouncing-loader {
  to {
    opacity: 0.1;
    transform: scale(1.3, 1.3);
  }
}

.bouncing-loader > div:nth-child(2) {
  animation-delay: 0.2s;
}

.bouncing-loader > div:nth-child(3) {
  animation-delay: 0.4s;
}

.icons i {
  position: relative;
  transform: translate(50%);
}

/*  products pages*/
.container .brandBar {
  width: 100%;
  height: 40px;
  background-color: var(--VioletAms);
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  margin: 10px;
}
/* en products pages*/

.audit {
  background-color: var(--light);
}

.parent {
  height: inherit;
}
